<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
      :tableStatus="tableStatus"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
    </default-table>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import { QueryDeviceTimeList, ExportDeviceTime } from '@/api/alarms';

import mixins from '@/mixins';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      tableLoading: true,
      gas: '',
      factory: '',
      searchParams: {
        site_id: ''
      },
      tableStatus: 'time',
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 64,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          minWidth: 100
        },
        {
          title: '加油机名称',
          key: 'tanker_name',
          minWidth: 100
        },
        {
          title: '端口编号',
          key: 'tax_port'
          // width: 120
        },
        {
          title: '加油机时间',
          key: 'tanker_time',
          minWidth: 100
          // width: 80
        },
        {
          title: '正常时间',
          key: 'time'
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      ...this.pagination
      // current: 1,
      // pageSize: 10
    });
  },
  methods: {
    handleSearch() {
      console.log('search');
    },
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryDeviceTimeList(params).then(res => {
        // console.log(res);
        this.data = res.data;
        if (res.code === 0) {
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        } else {
          this.pagination = {
            ...this.pagination,
            total: 0
          };
        }
        this.tableLoading = false;
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      ExportDeviceTime(params)
        .then(res => {
          this.$ExportFile(res, this.title);
          // const { code, msg } = res;
          // if (code !== 0) {
          //   this.$message({
          //     message: msg || '导出失败',
          //     type: 'error'
          //   });
          // }
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleView(e) {
      console.log(e);
    }
  }
};
</script>

<style lang="scss" scoped></style>
